import {RootState} from 'app/rootReducer';
import {RegenerateMagicContentType, SliceStatus} from 'interfaces';

import {createSelector} from '@reduxjs/toolkit';

import {MagicContentState} from './magicContentSlice';

export function getMagicContent(state: RootState): MagicContentState {
  return state.magicContent;
}

export const selectMagicContentFormStep = createSelector(
  getMagicContent,
  state => state.formStep,
);

export const selectMagicContentStatus = createSelector(
  getMagicContent,
  state => state.status,
);

export const selectMagicContentGenerateExpertSummaryStatus = createSelector(
  getMagicContent,
  state => state.generateExpertSummaryStatus,
);

export const selectMagicContentGenerateExpertSummaryIsPending = createSelector(
  getMagicContent,
  state => state.generateExpertSummaryStatus === SliceStatus.pending,
);

export const selectMagicContentStatusIsPending = createSelector(
  getMagicContent,
  state => state.status === SliceStatus.pending,
);

export const selectMagicContent = createSelector(
  getMagicContent,
  state => state.content,
);

export const selectMagicContentTopicSelection = createSelector(
  getMagicContent,
  state => state.topicSelection,
);

export const selectMagicContentIsRegenerating = (args: {
  element: RegenerateMagicContentType;
}) =>
  createSelector(
    getMagicContent,
    progress => progress.regenerateStatus[args.element] === SliceStatus.pending,
  );

export const selectMagicArticle = createSelector(
  getMagicContent,
  state => state.article,
);

export const selectMagicArticles = createSelector(
  getMagicContent,
  state => state.articles,
);

export const selectMagicImages = createSelector(
  getMagicContent,
  state => state.images,
);

export const selectMagicImagesCurrentPage = createSelector(
  getMagicContent,
  state => state.imagesCurrentPage ?? 1,
);

export const selectMagicImagesHasMore = createSelector(
  getMagicContent,
  state => (state.imagesCurrentPage ?? 1) < (state.imagesTotalPages ?? 1),
);

export const selectMagicImagesStatusIsPending = createSelector(
  getMagicContent,
  state => state.imagesStatus === SliceStatus.pending,
);

export const selectMagicContentDraftStatusIsPending = createSelector(
  getMagicContent,
  state => state.draftStatus === SliceStatus.pending,
);

export const selectProviderCanCreateMagicContent = createSelector(
  getMagicContent,
  state => state.providerCanCreate,
);

export const selectHasMagicContentDraft = createSelector(
  getMagicContent,
  state => !!state.article?.content?.length || !!state.content,
);
