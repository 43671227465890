import React, {lazy} from 'react';
import {AuthLayout} from 'components';
import {PublicRoute} from 'components/HOCs';
import {InsuranceStatus} from 'features/DirectBooking/constants';
import {ArticleLayout} from 'features/Provider/Marketing/Public/ArticleLayout';
import {useLocale, useQuery} from 'hooks';
import {Redirect} from 'react-router-dom';
import {componentLoader} from 'utils';

const WelcomePage = lazy(() =>
  componentLoader(() => import('features/User/Welcome')),
);
const LoginPage = lazy(() =>
  componentLoader(() => import('features/User/Login')),
);
const GetStartedPage = lazy(() =>
  componentLoader(() => import('features/User/GetStarted')),
);
const RegisterPage = lazy(() =>
  componentLoader(() => import('features/User/Register')),
);
const RequestPasswordTokenPage = lazy(() =>
  componentLoader(() => import('features/User/ResetPassword/RequestToken')),
);
const ValidateResetTokenPage = lazy(() =>
  componentLoader(() => import('features/User/ResetPassword/ValidateToken')),
);
const ChangePasswordPage = lazy(() =>
  componentLoader(() => import('features/User/ResetPassword/ChangePassword')),
);
const ReferralPage = lazy(() =>
  componentLoader(() => import('features/User/Referral')),
);
const PlansPage = lazy(() =>
  componentLoader(() => import('features/Payment/Plans')),
);

const DirectBookingPage = lazy(() =>
  componentLoader(() => import('features/DirectBooking')),
);
const DirectBookingSearchProvidersPage = lazy(() =>
  componentLoader(() => import('features/DirectBooking/SearchProviders')),
);
const DirectBookingProviderListPage = lazy(() =>
  componentLoader(() => import('features/DirectBooking/ProviderList')),
);
const DirectBookingProviderPage = lazy(() =>
  componentLoader(() => import('features/DirectBooking/Provider')),
);

const RegisterPracticePage = lazy(() =>
  componentLoader(
    () => import('features/User/Register/OutsideWellniteRegister'),
  ),
);

const ProviderRegister = lazy(() =>
  componentLoader(() => import('features/Provider/Register/Signup')),
);

const ProviderThankYou = lazy(() =>
  componentLoader(
    () => import('features/Provider/Register/Signup/ProviderThankYou'),
  ),
);

const SelfAwarenessPage = lazy(() =>
  componentLoader(() => import('features/Member/SelfAwareness')),
);

const IntakeLoginPage = lazy(() =>
  componentLoader(() => import('features/Intake/IntakeLogin')),
);

const UnsubscribeReminderPage = lazy(() =>
  componentLoader(() => import('features/Provider/Register/Unsubscribe')),
);

const ArticlePage = lazy(() =>
  componentLoader(() => import('features/Provider/Marketing/Public/Article')),
);

const directBookingLocalization = (basePath: string): JSX.Element | null => {
  const pathName = location.pathname;
  if (!pathName.includes('/direct-booking')) return null;

  const localePattern = /\/[a-z]{2}-[a-z]{2}\b/gi;
  const matches = pathName.match(localePattern);
  const utmSource = useQuery().get('utm_source') ?? '';
  if (!matches || matches.length > 1) {
    const cleanedPathName = pathName.replace(localePattern, '');
    return (
      <Redirect
        to={`${basePath}${cleanedPathName}?utm_source=${utmSource}`}
        key={`${basePath}${cleanedPathName}`}
      />
    );
  }
  if (!pathName.startsWith(basePath)) {
    return (
      <Redirect to={`${basePath}${pathName}`} key={`${basePath}${pathName}`} />
    );
  }
  return null;
};

export const PublicRoutes = (): JSX.Element[] => {
  const {locale} = useLocale();
  const basePath = '/' + locale;
  const directBookingRedirect = directBookingLocalization(basePath);

  if (directBookingRedirect) {
    return [directBookingRedirect];
  }

  return [
    <PublicRoute
      exact
      path="/"
      key="/"
      component={WelcomePage}
      layoutProps={{
        bgColor: 'bg-blue-600',
        lgColumns: 'lg-grid-cols-12',
      }}
    />,
    <PublicRoute
      exact
      path="/login"
      key="/login"
      component={LoginPage}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
        lgColumns: 'lg:grid-cols-12',
      }}
    />,
    <PublicRoute
      exact
      path="/referral"
      key="/referral"
      component={ReferralPage}
      layoutProps={{
        lgColumns: 'lg-grid-cols-1',
      }}
    />,
    <PublicRoute
      exact
      path="/forgot-password"
      key="/forgot-password"
      component={RequestPasswordTokenPage}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
        lgColumns: 'lg:grid-cols-12',
      }}
    />,
    <PublicRoute
      exact
      path="/reset/:token"
      key="/reset/:token"
      component={ValidateResetTokenPage}
    />,
    <PublicRoute
      exact
      path="/reset-password"
      key="/reset-password"
      layout={AuthLayout}
      component={ChangePasswordPage}
    />,
    <PublicRoute
      exact
      path="/get-started"
      key="/get-started"
      component={GetStartedPage}
      layoutProps={{
        bgColor: 'bg-lightOrange',
        lgColumns: 'grid-cols-12',
      }}
    />,
    <PublicRoute
      exact
      path={basePath + '/direct-booking/get-started'}
      key={basePath + '/direct-booking/get-started'}
      component={DirectBookingPage}
      layoutProps={{
        bgColor: 'bg-white',
        lgColumns: 'grid-cols-1',
      }}
    />,
    <PublicRoute
      exact
      path={`${basePath}/direct-booking/search-providers/:insuranceStatus(${InsuranceStatus.Insured}|${InsuranceStatus.Uninsured})?`}
      key={`${basePath}/direct-booking/search-providers/:insuranceStatus(${InsuranceStatus.Insured}|${InsuranceStatus.Uninsured})?`}
      component={DirectBookingSearchProvidersPage}
      layoutProps={{
        bgColor: 'bg-white',
        lgColumns: 'grid-cols-1',
      }}
    />,
    <PublicRoute
      exact
      path={basePath + '/direct-booking/providers'}
      key={basePath + '/direct-booking/providers'}
      component={DirectBookingProviderListPage}
      layoutProps={{
        bgColor: 'bg-lightOrange',
        lgColumns: 'grid-cols-1',
      }}
    />,
    <PublicRoute
      exact
      path={basePath + '/direct-booking/provider/:userId?'}
      key={basePath + '/direct-booking/provider/:userId?'}
      component={DirectBookingProviderPage}
      layoutProps={{
        bgColor: 'bg-lightOrange',
        lgColumns: 'grid-cols-1',
      }}
    />,
    <PublicRoute
      exact
      path="/plans"
      key="/plans"
      component={PlansPage}
      layoutProps={{
        bgColor: 'bg-lightOrange',
        lgColumns: 'grid-cols-1',
      }}
    />,
    <PublicRoute
      exact
      path="/(medicalcare_plus_therapy|medicalcare|new_therapy|zocdoc|zd)/register"
      key="/(medicalcare_plus_therapy|medicalcare|new_therapy|zocdoc|zd)/register"
      component={RegisterPage}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
        lgColumns: 'lg:grid-cols-12',
      }}
    />,
    <PublicRoute
      exact
      path="/practice/register/:providerId"
      key="/practice/register/:providerId"
      component={RegisterPracticePage}
    />,
    <PublicRoute
      exact
      path="/provider/registration"
      key="/provider/registration"
      component={ProviderRegister}
      layout={AuthLayout}
      layoutProps={{
        bgColor: 'bg-white',
      }}
    />,
    <PublicRoute
      exact
      path="/provider/registration/thankyou"
      key="/provider/registration/thankyou"
      component={ProviderThankYou}
    />,
    <PublicRoute
      exact
      path="/selfawareness/:memberId"
      key="/selfawareness/:memberId"
      component={SelfAwarenessPage}
    />,

    <PublicRoute
      exact
      path="/intake-login/:intakeType"
      key="/intake-login/:intakeType"
      component={IntakeLoginPage}
    />,

    <PublicRoute
      exact
      path="/unsubscribe/onboarding-reminder"
      key="/unsubscribe/onboarding-reminder"
      component={UnsubscribeReminderPage}
    />,

    <PublicRoute
      exact
      path="/post/:slug"
      key="/post/:slug"
      component={ArticlePage}
      layout={ArticleLayout}
    />,
  ];
};
