import {
  EndPoints,
  GenerateMagicContentArgs,
  GenerateMagicContentResponse,
  HttpMethods,
  MagicContentArticle,
  MagicContentDraftState,
  UnsplashSearchResults,
} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const generateExpertSummary = async (): Promise<
  Response<{message: {expertSummary: string}}>
> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {expertSummary: string};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GenerateExpertSummary,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateMagicContent = async (
  data: GenerateMagicContentArgs,
): Promise<Response<{message: {content: GenerateMagicContentResponse}}>> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {content: GenerateMagicContentResponse};
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.GenerateMagicContent,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const reGenerateMagicContent = async ({
  element,
  ...data
}: GenerateMagicContentArgs & {element: string}): Promise<
  Response<{message: {content: GenerateMagicContentResponse}}>
> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {content: GenerateMagicContentResponse};
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.ReGenerateMagicContent}/${element}` as unknown as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveMagicArticle = async (
  data: MagicContentArticle,
): Promise<Response<{message: {article: MagicContentArticle}}>> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {article: MagicContentArticle};
      },
      {articleData: MagicContentArticle}
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveMagicArticle,
      data: {articleData: data},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMagicImages = async ({
  count,
  query,
  page = 1,
}: {
  count: number;
  query: string;
  page?: number;
}): Promise<Response<{message: {photos: UnsplashSearchResults}}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {photos: UnsplashSearchResults};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetMagicImages}?query=${query}&page=${page}&per_page=${count}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMagicArticleBySlug = async ({
  slug,
}: {
  slug: string;
}): Promise<Response<{message: {article: MagicContentArticle}}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {article: MagicContentArticle};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetMagicArticleBySlug}/${slug}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMagicArticlesByProvider = async ({
  providerId,
}: {
  providerId: string;
}): Promise<Response<{message: {articles: MagicContentArticle[]}}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {articles: MagicContentArticle[]};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetMagicArticlesByProvider}/${providerId}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const checkIfProviderReachedDailyMagicContentLimit = async (): Promise<
  Response<{message: string}>
> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: string;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.CheckIfProviderReachedDailyMagicContentLimit as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const triggerDownloadUnsplashPhotoEvent = async (params: {
  url: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: string;
    }>({
      method: HttpMethods.POST,
      url: EndPoints.TriggerDownloadUnsplashPhotoEvent as unknown as EndPoints,
      data: {url: params.url},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMagicContentDraft = async (): Promise<
  Response<{message: {draft: MagicContentDraftState}}>
> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {draft: MagicContentDraftState};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.MagicContentDraft,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveMagicContentDraft = async (
  data: MagicContentDraftState,
): Promise<Response<{message: {draft: MagicContentDraftState}}>> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {draft: MagicContentDraftState};
      },
      {draftData: typeof data}
    >({
      method: HttpMethods.POST,
      url: EndPoints.MagicContentDraft,
      data: {draftData: data},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const MagicContentService = {
  generateExpertSummary,
  generateMagicContent,
  reGenerateMagicContent,
  saveMagicArticle,
  getMagicImages,
  getMagicArticleBySlug,
  getMagicArticlesByProvider,
  checkIfProviderReachedDailyMagicContentLimit,
  triggerDownloadUnsplashPhotoEvent,
  getMagicContentDraft,
  saveMagicContentDraft,
};
