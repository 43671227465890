import React from 'react';
import {FieldValues} from 'react-hook-form';

import {
  AbstractFormItem,
  AbstractFormItemProps,
} from '../Base/AbstractFormItem';

type TextAreaProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = AbstractFormItemProps<TFieldValues, TContext> & {
  containerClasses?: string;
  classes?: string;
  bgColor?: string;
  rows?: number;
  id: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export function FormTextArea<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  placeholder,
  rows = 5,
  bgColor = 'white',
  classes,
  ...rest
}: TextAreaProps<TFieldValues, TContext>) {
  return (
    <AbstractFormItem
      id={id}
      {...rest}
      render={({field: {value, onChange}}) => (
        <textarea
          placeholder={placeholder}
          id={id}
          rows={rows}
          value={value}
          onChange={e => {
            onChange(e);
            if (typeof rest.onChange === 'function') {
              rest.onChange(e);
            }
          }}
          className={`${classes} appearance-none border border-gray-400 focus:border-primary w-full p-3 leading-tight focus:outline-none rounded-md bg-${bgColor}`}
        />
      )}
    />
  );
}
