import React, {FC} from 'react';
import {FormError} from 'components';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import {DetachedDateSelect, FormTextInput} from 'components/Basic/Form/V2';
import dayjs from 'dayjs';
import {BookGroupAppointmentSchema} from 'definitions/Yup';
import {useRequesting} from 'hooks';
import {AppointmentTypes, SliceStatus} from 'interfaces';
import {createPortal} from 'react-dom';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {AppointmentCalendarContainer} from '../../Calendar2/CalendarContainer.styles';
import {bookGroupAppointment} from '../bookingActions';

import {BookingFormButtons} from './BookingFormButtons';

import 'react-day-picker/lib/style.css';

export const GroupBookingForm: FC<{
  resetCurrAppointment: () => void;
  onSuccessModal: ((arg: boolean) => void) | undefined;
  onCancel?: () => void;
  footerRef?: React.RefObject<HTMLDivElement>;
  selectedDate?: dayjs.Dayjs;
}> = ({
  resetCurrAppointment,
  onSuccessModal,
  onCancel,
  footerRef,
  selectedDate,
}) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const {
    handleSubmit,
    control,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: {
      date: selectedDate?.toDate() ?? tomorrow,
      title: '',
      time: selectedDate?.format('h:mma') || '',
    },
    resolver: yupResolver(BookGroupAppointmentSchema),
  });
  const {t} = useTranslation();

  const isSubmitting = useRequesting('appointment') === SliceStatus.pending;
  const dispatch = useDispatch();

  function setTimeInDate(date: Date, timeString: string) {
    const timeRegex = /^(\d+):(\d+)(am|pm)$/i;
    const match = timeString.match(timeRegex);

    if (!match) {
      throw new Error(t('invalidTimeStringFormat'));
    }

    let hours = parseInt(match[1], 10); // Specify radix as 10
    const minutes = parseInt(match[2], 10); // Specify radix as 10
    const period = match[3].toLowerCase();

    if (hours === 12) {
      hours = period === 'am' ? 0 : 12;
    } else {
      hours = period === 'am' ? hours : hours + 12;
    }

    date.setHours(hours);
    date.setMinutes(minutes);

    return date;
  }

  const onSuccess = () => {
    if (onSuccessModal) {
      onSuccessModal(true); // set modal
    }

    reset({
      time: '',
      title: '',
      date: undefined,
    });
    resetCurrAppointment();
  };

  const onError = () => {
    console.error('error');
  };
  const onBookAppointment = (values: {
    date: Date;
    time: string;
    title: string;
  }) => {
    const payload = {
      dates: [setTimeInDate(values.date, values.time).toISOString()],
      appointmentType: AppointmentTypes.group_call_with_therapist,
      duration: 60,
      title: values.title,
      onSuccess,
      onError,
    };

    dispatch(bookGroupAppointment(payload));
  };

  return (
    <div className="max-w-sm mx-auto">
      <form onSubmit={handleSubmit(onBookAppointment)} id="booking-form">
        <div className="mb-5">
          <FormTextInput
            id="title"
            name="title"
            type="text"
            control={control}
            placeholder={t('appointmentTitle')}
            inputClasses="font-inter font-medium text-sm leading-4 h-12 pl-3 rounded-xl"
            classes="m-0 pt-0"
          />
        </div>

        <div className="mb-5">
          <section>
            <Controller
              name="date"
              control={control}
              render={({field: {onChange, value}}) => (
                <DetachedDateSelect
                  onChange={onChange}
                  placeholder={t('selectDate')}
                  value={value}
                  disabledDays={[{before: tomorrow}]}
                  styledContainer={AppointmentCalendarContainer}
                  styles={{
                    ...detachedSearchBarStyles,
                    detachedButton: (isOpen: boolean, hasValue: boolean) => {
                      const styles = detachedSearchBarStyles.detachedButton!(
                        isOpen,
                        hasValue,
                      );
                      return {
                        ...styles,
                        borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
                        color: hasValue ? 'black' : '#6B6B6B',
                      };
                    },
                  }}
                />
              )}
            />
          </section>
          <FormError id="date-error" error={errors.date?.message} />
        </div>

        <div className="mb-5">
          <FormTextInput
            id="time"
            name="time"
            type="text"
            control={control}
            placeholder={t('appointmentTimeExample')}
            inputClasses="font-inter font-medium text-sm leading-4 h-12 pl-3 rounded-xl"
            classes="m-0 pt-0"
          />
        </div>

        {footerRef?.current
          ? createPortal(
              <BookingFormButtons
                appointmentType={AppointmentTypes.group_call_with_therapist}
                isPending={isSubmitting}
                selectedTime=""
                onCancel={onCancel}
              />,
              footerRef.current,
            )
          : null}
      </form>
    </div>
  );
};
