import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {MagicContentDraftState} from 'interfaces';
import {Epic, ofType} from 'redux-observable';
import {concat, from, of, tap} from 'rxjs';
import {catchError, filter, ignoreElements, mergeMap} from 'rxjs/operators';
import {MagicContentService} from 'services';

import {selectMagicContentDraftStatusIsPending} from './magicContentSelectors';
import {magicContentActions} from './magicContentSlice';

const generateExpertSummaryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateExpertSummary.match),
    mergeMap(({payload: {onSuccess}}) =>
      from(MagicContentService.generateExpertSummary()).pipe(
        mergeMap(res =>
          of(
            magicContentActions.generateExpertSummarySuccess(
              res.data.message.expertSummary,
            ),
          ).pipe(tap(() => onSuccess(res.data.message.expertSummary))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.generateExpertSummaryFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateExpertSummaryFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateExpertSummaryFailure.match),
    tap(({payload}) => {
      Toast({
        type: 'error',
        message: `${payload}. Try editing your bio in profile settings.`,
      });
    }),
    ignoreElements(),
  );

const generateMagicContentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateMagicContent.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MagicContentService.generateMagicContent(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.generateMagicContentSuccess(
              res.data.message.content,
            ),
          ).pipe(tap(() => onSuccess?.())),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.generateMagicContentFailure(message)),
          ),
        ),
      ),
    ),
  );

const generateMagicContentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateMagicContentFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const reGenerateMagicContent: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.reGenerateMagicContent.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MagicContentService.reGenerateMagicContent(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.reGenerateMagicContentSuccess({
              ...res.data.message.content,
              element: payload.element,
            }),
          ).pipe(tap(() => onSuccess?.(res.data.message.content))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(
              magicContentActions.reGenerateMagicContentFailure({
                error: message,
                element: payload.element,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const reGenerateMagicContentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.generateMagicContentFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const saveMagicArticleEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(magicContentActions.saveMagicArticle.match),
    mergeMap(({payload: {onSuccess, ...payload}}) =>
      from(MagicContentService.saveMagicArticle(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.saveMagicArticleSuccess(
              res.data.message.article,
            ),
          ).pipe(tap(() => onSuccess?.(res.data.message.article))),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.saveMagicArticleFailure(message)),
          ),
        ),
      ),
    ),
  );

const saveMagicArticleFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.saveMagicArticleFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getMagicImagesEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicImages.match),
    mergeMap(({payload}) =>
      from(MagicContentService.getMagicImages(payload)).pipe(
        mergeMap(res => [
          magicContentActions.getMagicImagesSuccess(res.data.message.photos),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicImagesFailure(message)),
          ),
        ),
      ),
    ),
  );

const getMagicImagesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicImagesFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getMagicArticleBySlugEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicArticleBySlug.match),
    mergeMap(({payload}) =>
      from(MagicContentService.getMagicArticleBySlug(payload)).pipe(
        mergeMap(res => [
          magicContentActions.getMagicArticleBySlugSuccess(
            res.data.message.article,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicArticleBySlugFailure(message)),
          ),
        ),
      ),
    ),
  );

const getMagicArticleBySlugFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicArticleBySlugFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const getMagicArticlesByProviderEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicArticlesByProvider.match),
    mergeMap(({payload}) =>
      from(MagicContentService.getMagicArticlesByProvider(payload)).pipe(
        mergeMap(res => [
          magicContentActions.getMagicArticlesByProviderSuccess(
            res.data.message.articles,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicArticlesByProviderFailure(message)),
          ),
        ),
      ),
    ),
  );

const checkIfProviderReachedDailyMagicContentLimitEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      magicContentActions.checkIfProviderReachedDailyMagicContentLimit.match,
    ),
    mergeMap(() =>
      from(
        MagicContentService.checkIfProviderReachedDailyMagicContentLimit(),
      ).pipe(
        mergeMap(res => [
          magicContentActions.checkIfProviderReachedDailyMagicContentLimitSuccess(
            res.data.message === 'You have reached the daily post limit',
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(
              magicContentActions.checkIfProviderReachedDailyMagicContentLimitFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const triggerDownloadUnsplashPhotoEventEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.triggerDownloadUnsplashPhotoEvent.match),
    mergeMap(({payload}) =>
      from(MagicContentService.triggerDownloadUnsplashPhotoEvent(payload)).pipe(
        mergeMap(() => [
          magicContentActions.triggerDownloadUnsplashPhotoEventSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(
              magicContentActions.triggerDownloadUnsplashPhotoEventFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

const getMagicContentDraftEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.getMagicContentDraft.match),
    mergeMap(() =>
      from(MagicContentService.getMagicContentDraft()).pipe(
        mergeMap(res =>
          of(
            magicContentActions.recoverMagicContentDraft(
              res.data.message.draft,
            ),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.getMagicContentDraftFailure(message)),
          ),
        ),
      ),
    ),
  );

const autoSaveMagicContentDraftEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(
      magicContentActions.generateMagicContent.type,
      magicContentActions.generateMagicContentSuccess.type,
      magicContentActions.reGenerateMagicContentSuccess.type,
      magicContentActions.setMagicArticle.type,
      magicContentActions.saveMagicArticleSuccess.type,
      magicContentActions.resetMagicContent.type,
    ),
    filter(() => !selectMagicContentDraftStatusIsPending(state$.value)),
    mergeMap(action => {
      let data: Partial<MagicContentDraftState> = {};

      switch (action.type) {
        case magicContentActions.generateMagicContentSuccess.type:
        case magicContentActions.reGenerateMagicContentSuccess.type:
        case magicContentActions.setMagicArticle.type:
          data = {formStep: 'finalEdits'};
          break;
        case magicContentActions.generateMagicContent.type:
          data = {
            formStep: 'topicSelection',
            content: null,
            article: null,
            topicSelection: null,
          };
          break;
        case magicContentActions.resetMagicContent.type:
        case magicContentActions.saveMagicArticleSuccess.type:
          data = {
            formStep: 'intro',
            content: null,
            article: null,
            topicSelection: null,
          };
          break;
        default:
      }

      const state = state$.value;
      const {content, article, topicSelection} = state.magicContent;
      const stateData = {content, article, topicSelection};

      const draftData: MagicContentDraftState = {
        ...stateData,
        ...data,
        formStep: data.formStep ?? state.magicContent.formStep ?? 'intro',
      };

      return of(magicContentActions.saveMagicContentDraft(draftData));
    }),
  );

const saveMagicContentDraftEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.saveMagicContentDraft.match),
    mergeMap(({payload}) =>
      from(MagicContentService.saveMagicContentDraft(payload)).pipe(
        mergeMap(res =>
          of(
            magicContentActions.saveMagicContentDraftSuccess(
              res.data.message.draft,
            ),
          ),
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'magicContent',
                message,
              }),
            ),
            of(magicContentActions.saveMagicContentDraftFailure(message)),
          ),
        ),
      ),
    ),
  );

const saveMagicContentDraftFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(magicContentActions.saveMagicContentDraftFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const magicContentEpics = [
  generateExpertSummaryEpic,
  generateExpertSummaryFailureEpic,
  generateMagicContentEpic,
  generateMagicContentFailureEpic,
  reGenerateMagicContent,
  reGenerateMagicContentFailureEpic,
  saveMagicArticleEpic,
  saveMagicArticleFailureEpic,
  getMagicImagesEpic,
  getMagicImagesFailureEpic,
  getMagicArticleBySlugEpic,
  getMagicArticleBySlugFailureEpic,
  getMagicArticlesByProviderEpic,
  checkIfProviderReachedDailyMagicContentLimitEpic,
  triggerDownloadUnsplashPhotoEventEpic,
  getMagicContentDraftEpic,
  autoSaveMagicContentDraftEpic,
  saveMagicContentDraftEpic,
  saveMagicContentDraftFailureEpic,
].flatMap(epic => epic);
