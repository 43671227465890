import {RootState} from 'app/rootReducer';
import {
  MemberProfile,
  PrescriberProfile,
  TherapistProfile,
  TNotification,
} from 'interfaces';
import log from 'loglevel';

import {Action, Dispatch, MiddlewareAPI} from '@reduxjs/toolkit';

import {AppCache} from './app-cache';

const db = new AppCache('WellniteDB');
export interface IParams {
  throttleTime: number;
  deleteCount: number;
}

async function checkIndexedDBStorageSpace(
  percentage: number,
): Promise<boolean> {
  if (navigator.storage && navigator.storage.estimate) {
    const {quota, usage} = await navigator.storage.estimate();
    if (quota && usage) {
      const spaceUsed = Math.floor(Number(((usage / quota) * 100).toFixed(2)));
      return spaceUsed < percentage;
    }
  }
  return false;
}

const cacheUserMiddleware = async ({
  user,
  deleteCount,
}: {
  user: MemberProfile | TherapistProfile | PrescriberProfile;
  deleteCount: number;
}) => {
  try {
    const isSpaceAvailable = await Promise.resolve(
      checkIndexedDBStorageSpace(10),
    );
    const count = await db.user.count();
    if (count >= deleteCount) {
      await db.user.clear();
      db.user.put(user);
    } else if (isSpaceAvailable) {
      db.user.put(user);
    } else {
      await db.user.clear();
      db.user.put(user);
    }
  } catch (error) {
    if (
      error.name === 'QuotaExceededError' ||
      (error.inner && error.inner.name === 'QuotaExceededError')
    ) {
      log.warn('QuotaExceeded');
    } else {
      log.warn(error);
    }
  }
};

export const cacheNotificationsMiddleware = async ({
  notifications,
  deleteCount,
}: {
  notifications: TNotification[];
  deleteCount: number;
}): Promise<void> => {
  try {
    const isSpaceAvailable = await checkIndexedDBStorageSpace(20);
    await db.transaction('rw', db.notifications, async () => {
      const count = await db.notifications.count();

      if (count >= deleteCount || notifications.length === 0) {
        await Promise.all([
          db.notifications.clear(),
          db.notifications.bulkPut(notifications),
        ]);
      } else if (isSpaceAvailable) {
        await Promise.resolve(db.notifications.bulkPut(notifications));
      } else {
        await Promise.all([
          db.notifications.clear(),
          db.notifications.bulkPut(notifications),
        ]);
      }
    });
  } catch (error) {
    if (
      error.name === 'QuotaExceededError' ||
      (error.inner && error.inner.name === 'QuotaExceededError')
    ) {
      log.warn('QuotaExceeded');
    } else {
      log.warn(error);
    }
  }
};

export const cacheProviderMembersMiddleware = async ({
  providerMembers,
  deleteCount,
}: {
  providerMembers: {[key: string]: MemberProfile};
  deleteCount: number;
}): Promise<void> => {
  try {
    const isSpaceAvailable = await checkIndexedDBStorageSpace(20);
    await db.transaction('rw', db.providerMembers, async () => {
      const members = Object.values(providerMembers);
      const count = await db.providerMembers.count();
      if (count >= deleteCount || members.length === 0) {
        await Promise.all([
          db.providerMembers.clear(),
          db.providerMembers.bulkPut(members),
        ]);
      } else if (isSpaceAvailable) {
        await db.providerMembers.bulkPut(members);
      } else {
        await Promise.all([
          db.providerMembers.clear(),
          db.providerMembers.bulkPut(members),
        ]);
      }
    });
  } catch (error) {
    if (
      error.name === 'QuotaExceededError' ||
      (error.inner && error.inner.name === 'QuotaExceededError')
    ) {
      log.warn('QuotaExceeded');
    } else {
      log.warn(error);
    }
  }
};

export const cacheServiceWorkerVersionMiddleware = async ({
  version,
}: {
  version: number;
}): Promise<void> => {
  try {
    const isSpaceAvailable = await checkIndexedDBStorageSpace(5);
    await db.transaction('rw', db.swVersions, async () => {
      if (isSpaceAvailable) {
        await db.swVersions.put(version);
      }
    });
  } catch (error) {
    if (
      error.name === 'QuotaExceededError' ||
      (error.inner && error.inner.name === 'QuotaExceededError')
    ) {
      log.warn('QuotaExceeded');
    } else {
      log.warn(error);
    }
  }
};

const clearStoreMiddleware = () => {
  // Reset user action fired
  db.user.clear();
  db.table('notifications').clear();
  db.table('providerMembers');
};

export const middleware = () => {
  let setUserDate: number = Date.now();
  let setNotifDate: number = Date.now();
  let setMembersDate: number = Date.now();
  return ({getState}: MiddlewareAPI<Dispatch<Action>, RootState>) =>
    (next: Dispatch<Action>) =>
    (action: Action) => {
      // Immediately dispatch action to middleware/reducers
      next(action);
      const {isCacheAvailable} = getState().user;

      if (isCacheAvailable) {
        const current: number = Date.now();
        switch (action?.type) {
          case 'ResetUser':
            clearStoreMiddleware();
            break;
          case 'SetUser': {
            const user = getState().user.current;
            if (current - setUserDate > 3000 && user) {
              setUserDate = Date.now();
              cacheUserMiddleware({user, deleteCount: 1});
            }
            break;
          }
          case 'notification/notificationsReceived':
            if (
              current - setNotifDate > 3000 &&
              action?.['payload']?.['page'] === 0
            ) {
              setNotifDate = Date.now();
              const {ids, entities} = getState().notification;
              const notifications = ids.map(
                id => entities[id],
              ) as TNotification[];
              cacheNotificationsMiddleware({notifications, deleteCount: 10});
            }
            break;
          case 'getMembersSuccess':
            if (current - setMembersDate > 3000) {
              setMembersDate = Date.now();
              const {members} = getState().provider;
              cacheProviderMembersMiddleware({
                providerMembers: members.members,
                deleteCount: 15,
              });
            }
            break;
          case 'setSwInstallTimestamp': {
            const swInstallTimestamp =
              getState().pushNotification.swInstallTimestamp;
            cacheServiceWorkerVersionMiddleware({version: swInstallTimestamp});
            break;
          }
          default:
            break;
        }
      }
    };
};
