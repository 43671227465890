import React, {FC, useState} from 'react';
import classNames from 'classnames';
import {Button} from 'components';
import dayjs from 'dayjs';
import {selectUserProfile} from 'features/User';
import {
  AppointmentDurations,
  AppointmentTypes,
  MemberProfile,
} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isUserFromUnitedStates} from 'utils';

import RecurringBookingCard from './RecurrinBookingV2/RecurringBookingCard';
import {Durations} from './AppointmentDurations2';
import {BookingFormV2} from './BookingFormV2';
import {GroupBookingForm} from './GroupBookingForm';
import {useAppointmentTypes} from './useAppointmentTypes';
import {useSessionTypes} from './useSessionTypes';

type Props = {
  appointmentType?: AppointmentTypes;
  member?: MemberProfile;
  onSuccessModal?: (arg: boolean) => void | undefined;
  selectedDate?: dayjs.Dayjs;
  setShowBookingModal: React.Dispatch<React.SetStateAction<boolean>>;
  footerRef?: React.RefObject<HTMLDivElement>;
};

export const BookAppointment: FC<Props> = ({
  appointmentType,
  member,
  onSuccessModal,
  selectedDate,
  setShowBookingModal,
  footerRef,
}) => {
  const currentUser = useSelector(selectUserProfile);
  const appointmentTypes = useAppointmentTypes(appointmentType);
  const {getSessions} = useSessionTypes();
  const [currAppointment, setCurrAppointment] = useState<{
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }>({
    type: appointmentTypes[0],
    duration: getSessions(appointmentTypes[0])[0].duration,
  });
  const {t} = useTranslation();

  /**
   *
   * @param type
   * @param duration
   */
  const onUpdateApptTypeAndDuration = (data: {
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }) => {
    setCurrAppointment(data);
  };

  const showBookingForm = () => {
    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      currAppointment.type.includes('recurring')
    )
      return (
        <RecurringBookingCard
          selectedDate={selectedDate}
          onCancel={() => setShowBookingModal(false)}
          onSuccess={() => setShowBookingModal(false)}
          member={member}
          footerRef={footerRef}
          duration={currAppointment.duration}
        />
      );

    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      currAppointment.type.includes('group')
    )
      return (
        <GroupBookingForm
          resetCurrAppointment={() => {}}
          onSuccessModal={onSuccessModal}
          onCancel={() => setShowBookingModal(false)}
          selectedDate={selectedDate}
          footerRef={footerRef}
        />
      );

    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      !currAppointment.type.includes('group')
    )
      return (
        <BookingFormV2
          appointmentType={currAppointment.type}
          duration={currAppointment.duration}
          member={member}
          selectedDate={selectedDate}
          onCancel={() => setShowBookingModal(false)}
          onSuccess={() => setShowBookingModal(false)}
          footerRef={footerRef}
        />
      );

    return null;
  };

  const renderAppointmentTypeButton = (
    supportedTypes: AppointmentTypes[],
    text: string,
  ) => {
    const selected = supportedTypes.includes(currAppointment?.type);
    return (
      <Button
        btnType="custom"
        className={classNames(
          'group flex items-start rounded-full max-w-sm mx-auto px-4 py-3 border cursor-pointer',
          {
            'border-primary/30': selected,
            'bg-white': !selected,
            'bg-primary/10': selected,
            'text-gray-500': !selected,
            'text-primary': selected,
          },
        )}
        outline
        textColor="auto"
        borderColor="transparent"
        onClick={() => {
          const selectedAppt = appointmentTypes.find(type =>
            supportedTypes.includes(type),
          );

          if (selectedAppt) {
            setCurrAppointment({
              type: selectedAppt,
              duration: getSessions(selectedAppt)[0].duration,
            });
          }
        }}
      >
        <p className={classNames('font-medium')}>{text}</p>
      </Button>
    );
  };

  return (
    <section className="w-full">
      <div className="flex flex-row items-center justify-between">
        <ul className="flex flex-row my-4">
          {renderAppointmentTypeButton(
            [
              AppointmentTypes.video_call_with_therapist,
              AppointmentTypes.doctor_consultation,
              AppointmentTypes.chat_with_coach,
            ],
            t('single'),
          )}
          {member
            ? null
            : renderAppointmentTypeButton(
                [AppointmentTypes.group_call_with_therapist],
                t('group'),
              )}
          {renderAppointmentTypeButton(
            [AppointmentTypes.recurring_session],
            t('recurring'),
          )}
        </ul>

        <Durations
          onUpdateApptTypeAndDuration={onUpdateApptTypeAndDuration}
          appointmentDuration={currAppointment?.duration}
          appointmentType={currAppointment.type}
        />
      </div>
      {showBookingForm()}
      {isUserFromUnitedStates(currentUser) && (
        <p className="bg-[#ffcb58]/[.15] text-sm rounded-xl p-5 text-center my-4 text-gray-600">
          {getSessions(currAppointment.type)[0].desc}
        </p>
      )}
    </section>
  );
};
