import React from 'react';
import classNames from 'classnames';
import {CloseIcon, FormError, FormLabel} from 'components';
import {FormTextArea, FormTextInput} from 'components/Basic/Form/V2';
import {NoteFormProps} from 'definitions/Yup';
import {Medication, SliceStatus} from 'interfaces';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrorsImpl,
  UseFormRegister,
} from 'react-hook-form';
import {useSelector} from 'react-redux';
import Select, {OnChangeValue} from 'react-select';
import styled from 'styled-components';

import {selectMedicationsStatus} from './notesSelectors';

type MedicationOptionType = {medicationName: string; medicationId: string};
export type NoteFormType = {
  chiefComplaint: string;
  patientNotes: string;
  plan: string;
  prescribeMedication: boolean;
  pharmacyService: string;
  medications: {
    medicationName: Medication[];
    medicationInstruction: string;
    quantity: number;
    daySupply: number;
    refillNumber: number;
    paymentType: string;
    cardHolderId: string;
    rxGroup: string;
    rxBin: string;
    pcn: string;
  }[];
  icdCodes: {
    value?: string;
    label?: string;
    _id?: string;
  }[];
  appointmentType: string;
  duration: number | null;
};

type Props = {
  fields: FieldArrayWithId<NoteFormProps, 'medications', 'id'>[];
  control: Control<NoteFormProps, any>;
  register: UseFormRegister<NoteFormProps>;
  errors: Partial<FieldErrorsImpl<NoteFormProps>>;
  remove: (index: number) => void;
  medicationList: MedicationOptionType[];
  autoSave: () => void;
};

const style = {
  control: (base: any) => ({
    ...base,
    border: '0 !important',
    boxShadow: '0 !important',
    background: 'transparent !important',
    '&:hover': {},
  }),
};
const Placeholder = styled.div<{error: string}>`
  color: ${props => (props.error ? '#f02848' : '#a0aebf')};
`;

function AddMedications({
  fields,
  control,
  register,
  errors,
  remove,
  medicationList,
  autoSave,
}: Props) {
  const MedicationsStatus = useSelector(selectMedicationsStatus);
  const isLoading = MedicationsStatus === SliceStatus.pending;

  return (
    <section>
      {fields.map(({id, refillNumber}, idx: number) => {
        return (
          <div
            key={id}
            className="mt-3 p-4 bg-gray-50 rounded-lg border border-gray-100"
          >
            <div className="flex justify-between items-center mb-4">
              <p className="font-bold text-base">Medication {idx + 1}</p>
              <button
                className="focus:outline-none"
                onClick={() => remove(idx)}
              >
                <CloseIcon fillColor="#6b6b6b" strokeWidth={20} />
              </button>
            </div>

            <article className="pr-2 mb-5">
              <FormLabel
                className="font-medium pb-1"
                htmlFor={`medications[${idx}].medicationName`}
              >
                Medication Name
              </FormLabel>
              <Controller
                name={`medications.${idx}.medicationName`}
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <Select
                    onChange={(
                      val: OnChangeValue<MedicationOptionType, boolean>,
                    ) => {
                      onChange(val);
                      autoSave();
                    }}
                    value={value ? (value as MedicationOptionType[]) : []}
                    getOptionLabel={(options: MedicationOptionType) =>
                      options.medicationName
                    }
                    getOptionValue={(options: MedicationOptionType) =>
                      options.medicationId
                    }
                    isLoading={isLoading}
                    onBlur={onBlur}
                    isMulti
                    options={medicationList}
                    className={classNames(
                      'bg-white border appearance-none w-full leading-tight focus:outline-none rounded-lg',
                      {
                        'border-red-500':
                          errors.medications &&
                          errors?.medications[idx]?.medicationName?.message,
                      },
                    )}
                    styles={style}
                    placeholder={
                      <Placeholder
                        error={
                          errors.medications &&
                          errors?.medications[idx]?.medicationName?.message
                        }
                      >
                        Select Medications
                      </Placeholder>
                    }
                    noOptionsMessage={searchText =>
                      `No Medication found: ${searchText.inputValue}`
                    }
                    loadingMessage={() => 'Loading Medications...'}
                  />
                )}
              />
              <FormError
                error={
                  errors.medications
                    ? errors?.medications[idx]?.medicationName?.message
                    : ''
                }
              />
            </article>

            <article>
              <FormTextArea
                control={control}
                id={`medications[${idx}].medicationInstruction`}
                name={`medications.${idx}.medicationInstruction`}
                label="Medication Instruction"
                placeholder="Medication Instruction"
                classes="bg-white placeholder-gray-500 w-full"
                containerClasses="pt-0"
                labelClasses="text-xs font-medium mb-3"
                errorClasses="!text-left text-xs font-medium"
                rows={3}
                onChange={autoSave}
              />
            </article>

            <div className="flex justify-between items-center mt-4">
              <article className="w-5/12">
                <FormTextInput
                  id={`medications[${idx}].quantity`}
                  name={`medications.${idx}.quantity`}
                  type="text"
                  control={control}
                  label="Quantity"
                  placeholder="Quantity"
                  inputClasses="font-inter font-medium text-sm leading-4 h-12 pl-3 placeholder-gray-500"
                  classes="m-0 pt-0"
                  onChange={autoSave}
                />
              </article>

              <article className="w-5/12">
                <FormTextInput
                  id={`medications[${idx}].daySupply`}
                  name={`medications.${idx}.daySupply`}
                  type="text"
                  control={control}
                  label="Day Supply"
                  placeholder="Day Supply"
                  inputClasses="font-inter font-medium text-sm leading-4 h-12 pl-3 placeholder-gray-500"
                  classes="m-0 pt-0"
                  onChange={autoSave}
                />
              </article>
            </div>

            <div className="mt-4">
              <FormLabel
                className="font-medium pb-1"
                htmlFor={`medications[${idx}].refillNumber`}
              >
                Refill
              </FormLabel>
              <div className="flex items-center">
                {[0, 1, 2, 3, 4, 5].map(v => (
                  <article key={v} className="flex items-center mr-3">
                    <input
                      key={v}
                      type="radio"
                      value={v}
                      id={`medications[${idx}].refillNumber`}
                      {...register(`medications.${idx}.refillNumber`)}
                      defaultChecked={refillNumber === v}
                      className="form-radio text-blue-600 transition duration-150 ease-in-out"
                      onChange={autoSave}
                    />
                    <label
                      htmlFor={`medications[${idx}].refillNumber`}
                      className="pl-1 block text-xs text-gray-700 text-left"
                    >
                      {v}
                    </label>
                  </article>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
}
export default AddMedications;
